import { OPPORTUNITY_PAYMENT, PAYMENT_TYPE_ENUM, currencies, CURRENCIES } from '../constants';

export const getOpportunityPaymentType = opportunity => {
  // if (!opportunityInfo.payment || !opportunityInfo.payment.length > 0) {
  //   return 'FREE';
  // }

  if (
    opportunity.is_paid &&
    // opportunity.payment_details &&
    // opportunity.payment_details.length > 0 &&
    opportunity.payment_options &&
    opportunity.payment_options[0] === PAYMENT_TYPE_ENUM.EXTERNAL
  ) {
    return OPPORTUNITY_PAYMENT.PAID_EXTERNAL;
  }

  if (
    opportunity.is_paid &&
    opportunity.payment_details &&
    opportunity.payment_details.length > 0 
    // && opportunity.payment_options &&
    // opportunity.payment_options[0] === PAYMENT_TYPE_ENUM.CARD
  ) {
    return OPPORTUNITY_PAYMENT.PAID_INTERNAL;
  }

  return OPPORTUNITY_PAYMENT.FREE;
};


export const getCurrenciesForAutoComplete = (currenciesList = currencies) =>  
  currenciesList.map( (code) => {
    const currency = { value: code };
    code = code.toUpperCase()
    if (Object.prototype.hasOwnProperty.call(CURRENCIES, code)) {
      currency.label =  CURRENCIES[code].name;
    } else {
      currency.label = code;
    }
    return currency;
  });
